/* You can add global styles to this file, and also import other style files */
@import './styles/_constants';
@import './styles/_icons';
@import './styles/carousels';
@import './styles/buttons'; 

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
@import '~ngx-owl-carousel-o/lib/styles/prebuilt-themes/owl.carousel.min.css';
@import '~ngx-owl-carousel-o/lib/styles/prebuilt-themes/owl.theme.default.min.css';

@import './app/theme/styles.scss';

body {
    overflow-x: hidden;
}
.dropdown-toggle:after{
    width: 10px;
    height: 6px;
    background-image: url(./assets/images/nav-icon.png);
    background-position: center;
    background-repeat: no-repeat;
    border: 0;
    top: 0;
    margin-left: .5rem;
}
.dropdown-toggler{
    &.dropdown-toggle::after{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin-top: 0;
        color: white;
        content: "\25bc";
        font-size: 10px;
        width: auto;
        height: auto;
    }
}
.account-tooltip{ 
    .arrow{
        display: none;
    }
    .tooltip-inner {
        background-color:#898989;
        font-size: 0.9rem;
    }
}
.create-customer-modal{
    .modal-content{
        max-width: 30.5rem;
    }
}

.toast {
    z-index: 50;

    .toast-header{
        border: 0;
    }

    &--logout {
        .toast-header {
            padding: .9375rem .625rem;
        }
        .toast-body {
            background-color: #ffffff;
        }
    }
}