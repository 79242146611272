@import 'constants';

.button,
button {
  &:not(:disabled):not(.disabled):active {
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  &:hover,
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.button {
  margin: 0;
  height: 3.5rem;
  line-height: calc(3.5rem - 2px);
  padding-top: 0;
  padding-bottom: 0;
  font-size: 1.25rem;
  font-weight: 400;
  outline: 0;
  border: 0;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;

  &:disabled,
  &.disabled {
    cursor: default;
  }

  &.small {
    font-size: 1rem;
    height: 2.75rem;
    line-height: calc(2.75rem - 2px);
  }
}

.button--icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button--primary {
  background-color: $primary;
  color: #fff;

  &:hover {
    background-color: darken($primary, 5);
  }
}

.button--secondary {
  background-color: $secondary;
  color: #fff;

  &:hover {
    background-color: darken($secondary, 5);
  }
}