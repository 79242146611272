@import '../../styles/shared';

.carousel.main-slider{
    .carousel-caption{
        padding-top: 10%;
        justify-content: flex-start !important;

        .caption__title, .caption__subtitle, .caption__paragraph {
            color: $brand-white !important;
        }

        @include media-breakpoint-down(sm){
            background-color: $brand-blue-1 !important;

            .caption__subtitle, .caption__paragraph{
                color: $brand-white !important;
            }
        }
    }
}