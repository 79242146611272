@import 'shared';
.carousel{
    .owl-stage{
      display: flex;
      margin-left: 1px;
    }
    .owl-item{
      display: flex;
  
      .category-box {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 2.5rem 0.9375rem 1.875rem;
        text-align: center;
        border-radius: 0.4375rem;
        background-color: #fff;
        transition: all 0.3s ease;
    
        a{
          outline: none;
          color: $primary;
          text-decoration: none;
          background: transparent;
          line-height: 1;
        }
      
        &:hover {
          background-color: $primary;
      
          svg {
            fill: #fff;
          }

          img{
            filter: brightness(0) invert(1);
          }
      
          h3 {
            color: #fff;
          }
        }
      
        img {
          fill: $primary;
          width: 3.75rem;
          height: auto;
          max-height: 3.75rem;
          margin: 0 auto 1.25rem;
          transition: all 0.3s ease;
        }
      
        h3 {
          font-size: 1rem;
          margin-bottom: 0;
          min-height: 3rem;
          transition: all 0.3s ease;
          color: $categories-carousel-item-title-color;
        }
      }
    } 
    .owl-nav {
      .owl-prev, .owl-next {
        width: 2.625rem;
        height: 2.625rem;
        line-height: 2.5rem;
        position: absolute;
        top: 50%;
        margin-top: -1.3125rem;
        background-color: $primary;
        border-radius: 50%;
        transition: all 0.3s ease;
        @include media-breakpoint-down(sm) {
           top: -2.625rem;
           width: 1.8rem;
           height: 1.8rem;
           line-height: 0.6;
        }
  
        &:focus {
          outline: none;
        }
  
        &:after {
          content: '';
          color: #fff;
          text-align: center;
          font-size: 1.375rem;
        }
  
        span {
          display: none;
        }
      }
  
      .owl-prev {
        left: -3.25rem;
        display: flex;
        justify-content: center;
        align-items: center;
        .prev-slide {
          background: url('../assets/images/nav-icon.png');
          background-position: center;
          background-size: cover;
          transform: rotate(180deg);
          height: 11px;
          width: 8px;
        }
  
        @include media-breakpoint-down(sm) {
          left: unset;
          right: 2.125rem;
        }
      }
  
      .owl-next {
        right: -3.25rem;
        display: flex;
        justify-content: center;
        align-items: center;
        .next-slide{
          background: url('../assets/images/nav-icon.png');
          background-position: center;
          background-size: cover;
          height: 11px;
          width: 8px;
        }
        @include media-breakpoint-down(sm) {
          right: 0;
        }
  
        
      }
    }
  }