@font-face {
  font-family: 'glyphter';
  src: url('/assets/fonts/glyphter.eot');
  src: url('/assets/fonts/glyphter.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/glyphter.woff') format('woff'),
  url('/assets/fonts/glyphter.ttf') format('truetype'),
  url('/assets/fonts/glyphter.svg#glyphter') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}


.icon {
  position: relative;
  display: block;
  font-family: 'glyphter';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow:before {
  content: '\0041';
}

.icon-favorite:before {
  content: '\0042';
}

.icon-fax:before {
  content: '\0043';
}

.icon-instagram:before {
  content: '\0044';
}

.icon-cart:before {
  content: '\0045';
}

.icon-linkedin:before {
  content: '\0046';
}

.icon-map:before {
  content: '\0047';
}

.icon-menu:before {
  content: '\0048';
}

.icon-mail:before {
  content: '\0049';
}

.icon-messanger:before {
  content: '\004a';
}

.icon-phone:before {
  content: '\004b';
}

.icon-share:before {
  content: '\004c';
}

.icon-shipping:before {
  content: '\004d';
}

.icon-twitter:before {
  content: '\004e';
}
